import React, { useState } from "react";
import Header from "../../components/content_wrapper/header";
import { useGetQueries } from "../../hooks/queryManagementApi/getQueries";
import moment from "moment";
import Pagination from "../../utils/pagination";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { queryAnswerSchema, queryAnswerValues } from "../../validations";
import { useSendAnswer } from "../../hooks/queryManagementApi/sendAnswer";
import NoRecordFound from "../../components/noRecordFound";
import CustomLaoder from "../../components/customLoader";

const QueriesListing: React.FC = () => {
  const [countData, setCountData] = useState<any>(5);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [finalValue, setFinalValue] = useState<string>("");
  const [answerID, setAnswerID] = useState<any>(null);

  const { mutateAsync } = useSendAnswer();
  const { data, isLoading, isError } = useGetQueries({
    page: pageNumber,
    limit: countData,
    search: finalValue,
  });
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: queryAnswerValues,
    validationSchema: queryAnswerSchema,
    onSubmit: async () => {
      try {
        const res: any = await mutateAsync({
          query_id: answerID._id,
          email: answerID.email,
          name: answerID?.name,
          query: answerID?.query,
          answer: values?.answer,
        });
        if (res?.status === true) {
          toast.success(res?.message);
          resetForm();
          const closeModelButton: any = document.getElementById("close-btn");
          closeModelButton.click();
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });
  const handlePrevPage = () => {
    setPageNumber((prevPage: any) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage: any) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue: number) => {
    setCountData(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleApplyClick = () => {
    setFinalValue(searchValue);
  };

  const handleCancelClick = () => {
    setFinalValue("");
    setSearchValue("");
  };

  return (
    <div className="main-wrapper">
      <Header title="Queries" />
      <CustomLaoder loader={isLoading}>
        <div className="content-wrapper">
          <section className="main-section spacer">
            <div className="container">
              <div className="col-xl-12">
                <form>
                  <div className="row justify-content-between g-3">
                    <div className="col-lg-8 col-xl-8 col-xxl-6">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm-7 col-xl-7">
                          <div className="form-block p-relative">
                            <input
                              type="search"
                              className="form-control"
                              id="search"
                              placeholder="Search by Name or Email"
                              value={searchValue}
                              onChange={handleSearchChange}
                            />
                            <span className="search-icon" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-5 col-xl-5">
                          <button
                            className="btn primary-btn"
                            onClick={handleApplyClick}>
                            Apply
                          </button>
                          <button
                            className="btn secondary-btn"
                            onClick={handleCancelClick}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {data?.data?.userQueries?.map((item: any) => (
                <div className="wrapper-querycard mt-4" key={item._id}>
                  <div className="row gy-3">
                    <div className="col-xl-12 mt-0">
                      <div className="tab-content-wrapper">
                        <div className="d-flex justify-content-between align-items-center text-capitalize">
                          <h2>{item?.name}</h2>
                          <button
                            className="btn primary-btn me-0 view-history-btn my-3"
                            data-bs-toggle="modal"
                            data-bs-target="#queryAnswer"
                            onClick={() => setAnswerID(item)}>
                            <span className="m-lg-1">Give Answer</span>
                          </button>
                        </div>
                        <p>{item?.query}</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        {/* <div>
                          <i className="fa-solid fa-phone" />
                          <span className="mt-0 m-lg-2">{`(${item?.countryCode}) ${item?.phoneNumber}`}</span>
                        </div> */}
                        <div>
                          <i className="fa-regular fa-envelope" />
                          <span className="mt-0 m-lg-2">{item?.email}</span>
                        </div>
                      </div>

                      <div>
                        <i className="fa-regular fa-calendar" />
                        <span className="mt-0 m-lg-2">
                          {moment.utc(item?.createdAt).format("ll")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {data?.status === false && <NoRecordFound />}
              {data?.status !== false && data?.data?.totalRecords > 1 && (
                <Pagination
                  totalPages={data?.data?.totalPages}
                  currentPage={pageNumber}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                  handleRowLimitChange={handleRowLimitChange}
                />
              )}
            </div>
          </section>
          <div
            className="modal fade common-modal "
            id="queryAnswer"
            tabIndex={-1}
            aria-labelledby="changeContentLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    id="close-btn"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetForm()}
                  />
                  <div className="common-box-body">
                    <h2>Answer</h2>
                    <div className="common-modal-form">
                      <div className="common-modal-field">
                        <label htmlFor="confirmPassword">Give Answer</label>
                        <div className="p-relative">
                          <textarea
                            name="answer"
                            value={values?.answer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="type answer"
                            className="form-control"
                          />
                          {touched.answer && errors.answer && (
                            <p className="error">{errors.answer}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="common-modal-btns">
                      <button
                        disabled={isLoading}
                        className="btn primary-btn me-0"
                        onClick={() => handleSubmit()}>
                        {isLoading ? "Sending.." : "Send"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomLaoder>
    </div>
  );
};

export default QueriesListing;
